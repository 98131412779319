import 'highlight.js/styles/agate.css'

import { colors, gutter, screenSizes } from '../theme'

import Highlight from 'react-highlight'
import Layout from '../components/layout'
import ProgressContainerBase from '../components/ProgressContainer'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

const Content = styled.div`
  * {
    user-select: auto;
  }

  padding-top: 140px;
  padding-bottom: 100px;

  @media (min-width: ${screenSizes.desktop}px) {
    padding-top: 40px;
  }

  li {
    color: white;
  }
  pre {
    padding: 0;
  }
  blockquote {
    border-radius: 4px;
    background-color: ${colors.footer};
    padding: 10px 30px 10px 30px;
    margin: 0 0 30px 0;
  }
  p {
    font-size: 11pt;
  }
  a {
    font-size: 11pt;
    text-decoration: underline;
  }
  li {
    font-size: 11pt;
    padding-left: 10px;
  }
  h4 a {
    font-size: inherit;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h1 {
    text-transform: uppercase;
    font-size: 32pt;
    padding-right: 40px;
    margin-bottom: 8px;
  }
  h2,
  h4 {
    font-size: 7pt;
    line-height: 14pt;
    text-transform: uppercase;
  }
  h4 {
    margin-top: 4px;
  }
`

const ContentBody = styled.div`
  padding-right: calc(${gutter.mobile}px + 15px);
  padding-left: 15px;

  @media (min-width: ${screenSizes.tablet}px) {
    padding-right: calc(${gutter.tablet}px + 15px);
  }

  @media (min-width: ${screenSizes.desktop}px) {
    padding-left: calc(100px + 30px);
    padding-right: calc(${gutter.desktop}px + 100px + 30px);
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    padding-right: calc(${gutter.desktopLarge}px + 100px + 30px);
  }
`

const AuthorBlock = styled.div`
  background: ${colors.footer};
`

const ProgressContainer = styled(ProgressContainerBase)`
  @media (min-width: ${screenSizes.desktop}px) {
    padding-top: 235px;
  }
`

const BlogPost = ({ data }) => {
  const post = data.post
  const { frontmatter, html } = post
  const { author } = frontmatter
  return (
    <Layout hideFooter>
      <Content>
        <ProgressContainer>
          <ContentBody>
            <Title>
              <div>
                <h1>{frontmatter.title}</h1>
                <h2>{frontmatter.subtitle}</h2>
              </div>
              <h4>{frontmatter.category}</h4>
            </Title>

            <h2>{frontmatter.date}</h2>
            <Highlight innerHTML={true}>{html}</Highlight>
            {author ? (
              <AuthorBlock>
                <h3>{author.id}</h3>
                <h4>{author.bio}</h4>
              </AuthorBlock>
            ) : null}
          </ContentBody>
        </ProgressContainer>
      </Content>
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.object,
  }),
}

export default BlogPost

export const query = graphql`
  query($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitle
        category
        tags
        author {
          id
          bio
          twitter
        }
      }
    }
  }
`
