import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { gutter, screenSizes } from '../theme'

import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: grid;
  padding-top: ${gutter.mobile}px;
  grid-template-columns: ${gutter.mobile}px calc(100vw - ${gutter.mobile}px);

  @media (min-width: ${screenSizes.tablet}px) {
    grid-template-columns: ${gutter.tablet}px calc(100vw - ${gutter.tablet}px);
    margin-top: ${gutter.tablet}px;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    grid-template-columns: ${gutter.desktop}px calc(100vw - ${gutter.desktop}px);
    margin-top: ${gutter.desktop}px;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    grid-template-columns: ${gutter.desktopLarge}px calc(
        100vw - ${gutter.desktopLarge}px
      );
    margin-top: ${gutter.desktopLarge}px;
  }
`

class ProgressContainerBase extends Component {
  static propTypes = {
    children: PropTypes.any,
  }

  render() {
    return (
      <Container id="progress-container">
        <div />
        <div>{this.props.children}</div>
      </Container>
    )
  }
}

export default ProgressContainerBase
